import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { connect } from 'react-redux';
import { closeModal } from '../../actions/modal';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { Button } from 'semantic-ui-react';
import { selectShiftDetail, saveShift } from '../../actions/shift';

const timeMap = [
  '7:00am', '7:30am', '8:00am', '8:30am',
  '9:00am', '9:30am', '10:00am', '10:30am',
  '11:00am', '11:30am', '12:00pm', '12:30pm', 
  '1:00pm', '1:30pm', '2:00pm', '2:30pm', 
  '3:00pm', '3:30pm', '4:00pm', '4:30pm', 
  '5:00pm', '5:30pm', '6:00pm', '6:30pm', 
  '7:00pm', '7:30pm', '8:00pm', '8:30pm', 
  '9:00pm', '9:30pm', '10:00pm', '10:30pm',
  '11:00pm'
];

const styles = {
  icon: {
    height: '50px',
    width: '50px',
    padding: '12.5px',
    fontSize: '25px',
    color: 'rgba(0,0,0,.35)',
    cursor: 'pointer',
  }
}

const Shift = ({ open, closeModal, selectShiftDetail, shift, saveShift }) => {
  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={open}
    >
      <div className='modal-header'>
        <h2>New Shift</h2>
        <Icon onClick={closeModal} style={styles.icon}>close</Icon>
      </div>
      <div className='modal-body'>
        <TextField
          id="shift-date"
          label="Date"
          type="date"
          value={shift.date}
          onChange={(e) => selectShiftDetail('date', e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <InputLabel shrink htmlFor='shift-start'>Start At</InputLabel>
        <NativeSelect
          input={<Input name="Start At" id="shift-start" />}
          value={shift.start}
          onChange={(e) => selectShiftDetail('start', e.target.value)}
        >
          {timeMap.map(( time, idx ) => (
            <option key={idx} value={idx}>{time}</option>
          ))}
        </NativeSelect>
        <InputLabel shrink htmlFor='shift-end'>End At</InputLabel>
        <NativeSelect
          value={shift.end}
          input={<Input name="End At" id="shift-end" />}
          onChange={(e) => selectShiftDetail('end', e.target.value)}
        >
          {timeMap.map(( time, idx ) => (
            idx > shift.start ? <option key={idx} value={idx}>{time}</option> : null
          ))}
        </NativeSelect>
        <Button onClick={() => saveShift()}>Save</Button>
      </div>
    </Drawer>
  )
}

const mapStateToProps = (state) => ({
  open: state.modal.shift,
  shift: state.shift,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => {
    dispatch(closeModal('shift'));
  },
  selectShiftDetail: (key, value) => {
    dispatch(selectShiftDetail(key, value));
  },
  saveShift: () => {
    dispatch(saveShift());
  }
});

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(Shift);
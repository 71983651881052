import uuid from 'uuidv4';
import { closeModal } from './modal';
const fetch = require('node-fetch');
const emailMama = 'https://us-central1-mama-mobile-238622.cloudfunctions.net/api/email/bookingSuccessMama';
const emailRmt = 'https://us-central1-mama-mobile-238622.cloudfunctions.net/api/email/bookingSuccessRmt';
// const createInvoice = 'https://us-central1-mama-mobile-238622.cloudfunctions.net/api/stripe/createSeparateInvoices';
const createInvoice = 'https://us-central1-mama-mobile-238622.cloudfunctions.net/api/stripe/createInvoice';

const timeMap = [
  '7:00am', '7:30am', '8:00am', '8:30am',
  '9:00am', '9:30am', '10:00am', '10:30am',
  '11:00am', '11:30am', '12:00pm', '12:30pm',
  '1:00pm', '1:30pm', '2:00pm', '2:30pm',
  '3:00pm', '3:30pm', '4:00pm', '4:30pm',
  '5:00pm', '5:30pm', '6:00pm', '6:30pm',
  '7:00pm', '7:30pm', '8:00pm', '8:30pm',
  '9:00pm', '9:30pm', '10:00pm', '10:30pm',
];

const getDaySchedule = (day, startIdx, duration, bookingId) => {
  const timeBlock = (duration + 60) / 30;
  // const timeBlock = 1;
  const newDay = { ...day };
  console.log('startIdx', startIdx);
  console.log('time', timeBlock);
  
  for (let i = 0; i < timeBlock; i += 1) {
    const timeIdx = startIdx + i;
    const time = timeMap[timeIdx];
    const newTime = newDay[time] ? newDay[time] : {};
    console.log('newDay', newDay);
    console.log(timeIdx, "timeIdx")

    let booking;

    if (i === 0) {
      booking = {
        start: true,
        id: bookingId,
      };
    } else if (i === (timeBlock - 1)) {
      booking = {
        id: bookingId,
        end: true,
      };
    } else {
      booking = {
        id: bookingId,
      };
    }

    newTime.booking = booking;
    newTime.status = 'booked';
    newDay[time] = newTime;
  }

  console.log(newDay);
  return newDay;
};

const saveBookingSchedule = (day, bookingId, booking, rmt, scheduleRef) => {
  scheduleRef.get().then((doc) => {
    const scheduleMonth = doc.data();
    let newDay;

    if (!scheduleMonth || !scheduleMonth[day]) {
      newDay = {
        [rmt]: {
          [bookingId]: booking,
        }
      }
    } else if (!scheduleMonth[day][rmt]) {
      newDay = { ...scheduleMonth[day] };
      newDay[rmt] = {
        [bookingId]: booking,
      }
    } else {
      newDay = { ...scheduleMonth[day] };
      newDay[rmt][bookingId] = booking;
    }
    console.log('newDay', newDay);

    if (!scheduleMonth) {
      scheduleRef.set({
        [day]: newDay
      }).then(() => console.log('update success'))
      .catch(err => console.log('error from updating schedule', err));
    } else {
      scheduleRef.update({
        [day]: newDay,
      }).then(() => console.log('update success'))
      .catch(err => console.log('error from updating schedule', err));
    }
  }).catch((err) => console.log('error from saving booking schedule', err))
}

export const saveBooking = (bookingObj) => (
  (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const state = getState();
    const { services, mama_id, rmt_id, location, date, timeIdx, rmt_name, rmt_number } = bookingObj;
    const mama = state.mama;
    let subtotal = 0;
    let duration = 0;
    const formattedDate = date.split('-');
    const year = formattedDate[0];
    const month = parseInt(formattedDate[1]) - 1;
    const day = parseInt(formattedDate[2]);
    const apptDate = new Date(year, month, day).toDateString();
    const bookingId = uuid();
    const timeIdxNumber = Number(timeIdx);

    const serviceNames = services.map((s) => {
      const service = state.services[s];
      subtotal += service.price;
      duration += service.duration;
      return ({
        name: service.name,
        duration: service.duration,
      });
    });

    const booking = {
      services,
      rmt_id,
      mama_id,
      mama: {
        email: mama_id,
        name: mama.name,
      },
      location,
      payment: {
        status: 'unpaid',
        subtotal,
        tip: 0,
        promo: 0,
        tax: (subtotal * 0.13).toFixed(2),
        total: (subtotal * 1.13).toFixed(2),
      },
      promoCode: '',
      appt: {
        date: apptDate,
        time: timeMap[timeIdxNumber],
        duration,
        status: 'booked',
      },
      booking_date: new Date().toDateString(),
    };

    const bookingRef = firestore.collection('bookings').doc(bookingId);
    const scheduleRef = firestore.collection('schedules').doc(rmt_id);
    const bookingScheduleRef = firestore.collection(`bookings_schedule_2019`).doc(month.toString());

    bookingRef.set(booking)
    .then(() => {
      scheduleRef.get()
        .then((doc) => {
          const updateDay = `${month}.${day}`;
          if (doc.exists) {
            const schedule = doc.data();
            const scheduleMonth = schedule[month] ? schedule[month] : {};
            const scheduleDay = scheduleMonth[day] ? scheduleMonth[day] : {};
            const newDay = getDaySchedule(scheduleDay, timeIdxNumber, duration, bookingId);

            scheduleRef.update({
              [updateDay]: newDay,
            }).then(() => console.log('done', newDay))
              .catch(err => console.log('error from updating schedule', err));
          } else {
            const newDay = getDaySchedule({}, timeIdxNumber, duration, bookingId);
            const scheduleMonth = {
              [day]: newDay,
            }
            scheduleRef.set({
              [month]: scheduleMonth,
            }).then(() => console.log('done', newDay))
              .catch(err => console.log('error from updating schedule', err));
          }
          
          dispatch(closeModal('booking'));
        }).catch(err => console.log('error from getting schedule', err));
    })
    .catch(err => console.log('error from setting booking', err));

    saveBookingSchedule(day, bookingId, booking, rmt_id, bookingScheduleRef);

    fetch(createInvoice, {
      method: 'POST',
      body: JSON.stringify({
        email: mama_id,
        customer: mama.stripe,
        name: mama.name,
        services,
        rmt: rmt_name,
        rmtNum: rmt_number,
        date: apptDate,
        bookingId: bookingId,
        papaName: '',
        babyName: '',
      }),
    }).then(() => console.log('done stripe invoice'))
      .catch(err => console.log('error from stripe invoice', err));

    fetch(emailMama, {
      method: 'POST',
      body: JSON.stringify({
        email: mama_id,
        services: serviceNames,
        // location: '',
        location: location.address,
        rmt: rmt_name,
        date: apptDate,
        time: timeMap[timeIdx],
        // time: '7:15pm',
        booking,
        booking_id: bookingId,
      }),
    }).then(() => console.log('done mama email'))
      .catch(err => console.log('error from mama email', err));

    fetch(emailRmt, {
      method: 'POST',
      body: JSON.stringify({
        name: mama.name,
        services: serviceNames,
        // location: '',
        location: location.address,
        rmt: rmt_name,
        rmtEmail: rmt_id,
        date: apptDate,
        time: timeMap[timeIdx],
        // time: '7:15pm',
        booking,
        booking_id: bookingId,
      }),
    }).then(() => console.log('done rmt email'))
      .catch(err => console.log('error from rmt email', err));
  }
);
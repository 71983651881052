import React from 'react';
import { connect } from 'react-redux';
import Week from './Week';
import Calendar from 'react-calendar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { selectShiftDetail, selectShift } from '../../actions/shift';
import Switch from '@material-ui/core/Switch';
import selectDate from '../../actions/selectDate';
import { getSchedule } from '../../actions/getSchedule';
import mapWeek from '../../actions/mapWeek';
import getRMTs from '../../actions/getRMT';
import './calendar.css';
import Shift from './Shift';
import Booking from './Booking';
import NewMama from './NewMama';
import BookingInfo from './BookingInfo';

class Schedule extends React.Component {
  componentDidMount = () => {
    this.props.getRMTs();
  }

  render = () => {
    const { date, selectDate, shiftSelect, selectShift, shift, selectRmt, rmts } = this.props;
    return (
      <div className='calendar'>
        <Calendar value={date} onChange={(day) => selectDate(day)} locale='en-US' />
        <div id='calendar-selectors'>
          <FormControlLabel
              control={
                <Switch
                  checked={shiftSelect}
                  onChange={() => selectShift()}
                  color="primary"
                />
              }
              label="Shift"
            />
          <InputLabel htmlFor='rmt'>SELECT A RMT:</InputLabel>
          <NativeSelect
            input={<Input name="rmt" id="rmt" />}
            value={shift.rmt}
            onChange={(e) => selectRmt('rmt', e.target.value)}
          >
            {rmts.map(rmt => (
              <option key={rmt.id} value={rmt.id}>{rmt.name}</option>
            ))}
          </NativeSelect>
        </div>
        <Week />
        <Shift />
        <Booking />
        <NewMama />
        <BookingInfo />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  date: state.date,
  shiftSelect: state.shift.shiftSelect,
  shift: state.shift,
  rmts: state.rmts,
});

const mapDispatchToProps = dispatch => ({
  selectDate: (date) => {
    dispatch(selectDate(date));
    dispatch(mapWeek(date));
  },
  selectShift: () => {
    dispatch(selectShift());
  },
  selectRmt: (key, value) => {
    dispatch(selectShiftDetail(key, value));
    dispatch(getSchedule());
  },
  getRMTs: () => {
    dispatch(getRMTs());
  }
});

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(Schedule);
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import date from './date';
import week from './week';
import mama from './mama';
import schedule from './schedule';
import modal from './modal';
import shift from './shift';
import saveMama from './saveMama';
import rmts from './rmts';
import bookings from './bookings';
import services from './services';
import bookingsSchedule from './bookingsSchedule';


export default history => combineReducers({
  bookingsSchedule,
  date,
  week,
  schedule,
  modal,
  shift,
  saveMama,
  mama,
  rmts,
  services,
  bookings,
  router: connectRouter(history),
  firestore: firestoreReducer,
  firebase: firebaseReducer,
});
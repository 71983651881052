const getBookings = () => (
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore.collection('bookings').get().then((querySnapshot) => {
      const bookings = {};
      querySnapshot.forEach(doc => bookings[doc.id] = doc.data());
      dispatch({ 
        type: 'GET_BOOKINGS',
        bookings,
      });
    }).catch((error) => console.log(error))
  } 
);

export default getBookings;
import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { connect } from 'react-redux';
import { closeBookingInfo } from '../../actions/modal';
import { saveMama } from '../../actions/mamas';
import Icon from '@material-ui/core/Icon';
import { Button } from 'semantic-ui-react';
import cancelBooking from '../../actions/cancelBooking';
import payBooking from '../../actions/payBooking';

const styles = {
  icon: {
    height: '50px',
    width: '50px',
    padding: '12.5px',
    fontSize: '25px',
    color: 'rgba(0,0,0,.35)',
    cursor: 'pointer',
  },
  autoComplete: {
    border: 'none',
    borderBottom: '1px solid gray',
    paddingTop: '7px',
    paddingBottom: '7px',
    marginBottom: '20px'
  },
}

const FAKEBOOKING = {
  location: {},
  appt: {},
  services: [],
  payment: {
    tax: 12,
    total: 12,
  },
  papa: {
    name: '',
    email: '',
  },
  baby: {
    name: '',
  }
}

const renderPapa = (booking) => {
  if (booking.papa && booking.papa.name) {
    return (
      <div>
        <p>Papa Name: {booking.papa.name}</p>
        <p>Papa Email: {booking.papa.email}</p>
      </div>
    )
  }
}

const renderBaby = (booking) => {
  if (booking.baby && booking.baby.name) {
    return (
      <div>
        <p>Baby Name: {booking.baby.name}</p>
      </div>
    )
  }
}

const BookingInfo = ({ closeModal, bookingInfo, bookings, cancelBooking, payBooking }) => {
  const booking = bookings[bookingInfo.booking] ? bookings[bookingInfo.booking] : FAKEBOOKING;

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={bookingInfo.open}
    >
      <div className='modal-header'>
        <h2>Booking Info</h2>
        <Icon onClick={closeModal} style={styles.icon}>close</Icon>
      </div>
      <div className='modal-body'>
        <p>{`id: ${bookingInfo.booking}`}</p>
        <p>{`Mama Name: ${booking.mama && booking.mama.name}`}</p>
        <p>{`Mama Email: ${booking.mama_id}`}</p>
        <p>{`Tel: ${booking.mama && booking.mama.tel}`}</p>
        {renderPapa(booking)}
        {renderBaby(booking)}
        <br/>
        <p>{`Location: ${booking.location.address} ${booking.location.unit && booking.location.unit}`}</p>
        <p>{`Booking Date: ${booking.appt.date} - ${booking.appt.time}`}</p>
        <p>{`Total Duration: ${booking.appt.duration} min`}</p>
        <p><b>Services:</b></p>
        {booking.services.map((service) => <p key={service}>{service}</p>)}
        {booking.promoCode ? <p>{`PromoCode: ${booking.promoCode}`}</p> : null}
        <p>{`Subtotal: ${booking.payment.subtotal}`}</p>
        {booking.promoCode ? <p>{`Promo: ${booking.payment.promo}`}</p> : null}
        <p>{`Tip: ${booking.payment.tip}`}</p>
        <p>{`Tax: ${booking.payment.tax}`}</p>
        <p>{`Total: ${booking.payment.total}`}</p>
        <p>{`Payment Status: ${booking.payment.status}`}</p>
        <p>{`Notes: ${booking.notes}`}</p>
        <Button onClick={() => cancelBooking(booking, bookingInfo.booking)}>Cancel Booking</Button>
        <br/>
        <Button onClick={() => payBooking(bookingInfo.booking)}>Paid</Button>
      </div>
    </Drawer>
  )
}

const mapStateToProps = (state) => ({
  bookingInfo: state.modal.bookingInfo,
  bookings: state.bookings,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => {
    dispatch(closeBookingInfo());
  },
  saveMama: (mama) => {
    dispatch(saveMama(mama));
  },
  cancelBooking: (booking, id) => {
    dispatch(cancelBooking(booking, id));
  },
  payBooking: (id) => {
    dispatch(payBooking(id));
  }
});

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(BookingInfo);
const initState = new Date();

const date = (state = initState, action) => {
  switch (action.type) {
    case 'SELECT_DATE':
      return action.date;
    default:
      return state;
  }
}

export default date;
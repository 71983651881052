const payBooking = (bookingId) => (
  (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const bookingRef = firestore.collection('bookings').doc(bookingId);
    const statusUpdate = 'payment.status'

    bookingRef.update({
      [statusUpdate]: 'paid',
    })
      .then(() => console.log('appt paid'))
      .catch((err) => console.log('error from paying booking', err));
  }
);

export default payBooking;
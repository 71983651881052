const initState = {};

const mama = (state = initState, action) => {
  switch (action.type) {
    case 'SELECT_MAMA':
      return action.mama;
    default:
      return state;
  }
}

export default mama;
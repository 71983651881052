import { getSchedule } from './getSchedule';

export const selectShift = () => {
  return {
    type: 'SELECT_SHIFT',
  }
};

export const selectShiftId = (shiftId) => {
  return {
    type: 'SELECT_SHIFT_ID',
    shiftId,
  }
};

export const selectShiftDetail = (key, value) => {
  return {
    type: 'SELECT_SHIFT_DETAIL',
    key,
    value
  }
};

const timeMap = [
  '7:00am', '7:30am', '8:00am', '8:30am',
  '9:00am', '9:30am', '10:00am', '10:30am',
  '11:00am', '11:30am', '12:00pm', '12:30pm', 
  '1:00pm', '1:30pm', '2:00pm', '2:30pm', 
  '3:00pm', '3:30pm', '4:00pm', '4:30pm', 
  '5:00pm', '5:30pm', '6:00pm', '6:30pm', 
  '7:00pm', '7:30pm', '8:00pm', '8:30pm', 
  '9:00pm', '9:30pm', '10:00pm', '10:30pm'
];

export const saveShift = () => (
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const shift = getState().shift;
    const date = shift.date.split('-');
    const month = Number(date[1]) - 1;
    const day = Number(date[2]);
    const start = Number(shift.start); 
    const end = Number(shift.end);
    const rmt = shift.rmt;

    firestore.collection('schedules').doc(rmt)
    .get()
    .then(snapshot => {
      const schedule = snapshot.data() || {};
      let sMonth = schedule[month];

      if (!sMonth) {
        sMonth = {}
        schedule[month] = sMonth;
      }

      let sDay = sMonth[day];
      if (!sDay) {
        sDay = {};
        schedule[month][day] = sDay;
      }

      for (let i = start; i < end; i++) {
        const time = timeMap[i];
        if (!sDay[time]) {
          sDay[time] = {
            status: 'available'
          }
          if (i === start) {
            sDay[time] = {
              ...sDay[time],
              start: true,
            }
          } else if (i === end - 1) {
            sDay[time] = {
              ...sDay[time],
              end: true,
            }
          }
        }
      }

      if (snapshot.exists) {
        firestore.collection('schedules').doc(rmt).update(schedule)
        .then(() => {
          dispatch({ type: 'CLOSE_MODAL', modal: 'shift' });
        })
        .catch(error => console.log('err', error))
      } else {
        firestore.collection('schedules').doc(rmt).set(schedule)
        .then(() => {
          dispatch({ type: 'CLOSE_MODAL', modal: 'shift' });
        })
        .catch(error => console.log('err', error))
      }
    }).catch((error) => console.log('err', error));
  } 
);

export const cancelShift = () => (
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const state = getState();
    const date = state.modal.date;
    const dateArr = date.split('-');
    const month = Number(dateArr[1]) - 1;
    const day = Number(dateArr[2]);
    const time = timeMap[state.modal.time];
    const rmt = state.shift.rmt;
    const dayUpdate = `${month}.${day}`;
    const scheduleRef = firestore.collection('schedules').doc(rmt);

    scheduleRef.get()
    .then(snapshot => {
      const schedule = snapshot.data();
      const rmtDay = schedule[month][day];

      delete rmtDay[time];
      console.log(rmtDay);
      
      scheduleRef.update({
        [dayUpdate]: rmtDay,
      })
      .then(() => {
        dispatch({ type: 'CLOSE_MODAL', modal: 'booking' });
      }).catch(error => console.log('error from schedule update', error));
    }).catch((error) => console.log('error from get schedule', error));
  } 
);
const getRMTs = () => (
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore.collection('rmts').get().then((querySnapshot) => {
      const rmts = [];
      querySnapshot.forEach(doc => {
        const rmt = {
          id: doc.id,
          name: doc.data().name,
          rmtNumber: doc.data().rmtNumber,
        }

        rmts.push(rmt);
      });
      dispatch({ 
        type: 'GET_RMTS',
        rmts: rmts,
      });
    }).catch((error) => console.log(error))
  } 
);

export default getRMTs;
const initState = {
  shift: false,
  booking: false,
  newMama: false,
  bookingInfo: {
    open: false,
    booking: '',
  },
  date: '',
  time: 0,
};

const modal = (state = initState, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        [action.modal]: true,
      };
    case 'OPEN_BOOKINGINFO_MODAL':
      return {
        ...state,
        bookingInfo: {
          booking: action.booking,
          open: true,
        }
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        [action.modal]: false,
      };
    case 'CLOSE_BOOKINGINFO_MODAL':
      return {
        ...state,
        bookingInfo: {
          open: false,
          booking: '',
        },
      };
    case 'SET_MODAL_DATE':
      return {
        ...state,
        date: action.date
      };
    case 'SET_MODAL_TIME':
      return {
        ...state,
        time: action.time
      }
    default:
      return state;
  }
}

export default modal;
import moment from 'moment';

const mappingWeek = (date) => {
  return [
    moment(date).day(0),
    moment(date).day(1),
    moment(date).day(2),
    moment(date).day(3),
    moment(date).day(4),
    moment(date).day(5),
    moment(date).day(6),
  ]
}

const mapWeek = (date) => {
  return {
    type: 'MAP_WEEK',
    week: mappingWeek(date)
  }
};

export default mapWeek;
import React from 'react';
import { Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { openModal, openBooking, setModalTime, setModalDate, openBookingInfo } from '../../actions/modal';
import { selectShiftId, selectShiftDetail } from '../../actions/shift';
import { getSchedule } from '../../actions/getSchedule'; 
import getBookings from '../../actions/getBookings';
import firebase from 'firebase';
import moment from 'moment';

const timeMap = [
  '7:00am', '7:30am', '8:00am', '8:30am',
  '9:00am', '9:30am', '10:00am', '10:30am',
  '11:00am', '11:30am', '12:00pm', '12:30pm', 
  '1:00pm', '1:30pm', '2:00pm', '2:30pm', 
  '3:00pm', '3:30pm', '4:00pm', '4:30pm', 
  '5:00pm', '5:30pm', '6:00pm', '6:30pm', 
  '7:00pm', '7:30pm', '8:00pm', '8:30pm', 
  '9:00pm', '9:30pm', '10:00pm', '10:30pm',
  '11:00pm', '11:30pm'
];

const timeMapDisplay = [
  '7 am', '7:30 am', '8 am', '8:30 am',
  '9 am', '9:30 am', '10 am', '10:30 am',
  '11 am', '11:30 am', '12 pm', '12:30 pm', 
  '1 pm', '1:30 pm', '2 pm', '2:30 pm', 
  '3 pm', '3:30 pm', '4 pm', '4:30 pm', 
  '5 pm', '5:30 pm', '6 pm', '6:30 pm', 
  '7 pm', '7:30 pm', '8 pm', '8:30 pm', 
  '9 pm', '9:30 pm', '10 pm', '10:30 pm',
  '11:00 pm', '11:30 pm'
];

class Week extends React.Component {

  constructor(props) {
    super(props);
    this.unsub = null;
  }

  componentWillMount = () => {
    this.props.getBookings();
  }
  
  componentDidMount = () => {
    this.props.getSchedule();
    const db = firebase.firestore();
    this.unsub = db.collection('schedules').onSnapshot(() => {
      this.props.getSchedule();
    });
  }

  componentWillUnmount() {
    this.unsub();
  }

  makeId = (date, timeIdx) => {
    let month = date.month() + 1;
    let day = date.date();
    if (month < 10) { month = `0${month}` }
    if (day < 10) { day = `0${day}` }

    return `${month}-${day}-${timeIdx}`
  }

  findEnd = (day, startIdx) => {
    for (let i = startIdx + 1; i <= timeMap.length; i++) {
      const time = timeMap[i];
      const timeSlot = day[time] || {};
      if (timeSlot.end) {
        return timeMapDisplay[i + 1];
      }
    }
  }

  findEndBooking = ( startIdx, duration ) => {
    const endIdx = startIdx + (duration + 60) / 30;
    return timeMapDisplay[endIdx];
  }

  renderCalendar = (date, time, timeIdx) => {
    const { schedule, openShift, openBookingModal, openBookingInfo, bookings, services } = this.props;
    const today = moment();

    const month = date.month();
    const day = date.date();

    const scheduleMonth = schedule[month];
    if (scheduleMonth) {
      const scheduleDay = scheduleMonth[day];
      
      if (scheduleDay) {
        const scheduleTime = scheduleDay[time];
        
        if (scheduleTime) {
          if (scheduleTime.status === 'available') {
            if (scheduleTime.start) {
              let start = timeMapDisplay[timeIdx];
              let end = this.findEnd(scheduleDay, timeIdx); 
              return (
                <Table.Cell
                  key={this.makeId(date, timeIdx)} 
                  className='time-slot' 
                >
                  <div 
                    id={this.makeId(date, timeIdx)}
                    className='events available'
                    onClick={(e)=>{openBookingModal(e.target.id)}}
                  >
                    {`${start} - ${end}`}
                  </div>
                </Table.Cell>
              )
            }
            
            return (
              <Table.Cell 
                key={this.makeId(date, timeIdx)} 
                className='time-slot' 
              >
                <div 
                  id={this.makeId(date, timeIdx)}
                  className='events available'
                  onClick={(e)=>{openBookingModal(e.target.id)}}
                />
              </Table.Cell>
            )
          }

          if (scheduleTime.status === 'booked') {
            const { start, id } = scheduleTime.booking;
            const booking = bookings[id];
            console.log('booking', id)

            if (start) {
              let start = timeMapDisplay[timeIdx];
              console.log('bookingid', booking)
              let end = this.findEndBooking(timeIdx, booking.appt.duration); 
              return (
                <Table.Cell
                  key={this.makeId(date, timeIdx)} 
                  className='time-slot active' 
                >
                  <div className='events booked' id={id} onClick={(e)=>{openBookingInfo(e.target.id)}}>
                    {`${start} - ${end}`}
                    <div className='booking-info' id={id}>
                      <b id={id}>{booking.mama_id}</b>
                      {booking.services.map(service => (
                        <div id={id}>
                          {`${services[service].name} - ${services[service].duration === 0 ? 10 : services[service].duration} min`}
                        </div>  
                      ))}
                    </div>
                  </div>
                </Table.Cell>
              )
            }
            
            return (
              <Table.Cell
                key={this.makeId(date, timeIdx)} 
                className='time-slot active' 
              >
                <div 
                  className='events booked' 
                  id={id}
                  onClick={(e)=>{openBookingInfo(e.target.id)}}
                />
              </Table.Cell>
            )
          }
        }
      }
    }

    return (
      <Table.Cell 
        key={this.makeId(date, timeIdx)} 
        className={date.isBefore(today, 'date') ? 'time-slot disabled' : 'time-slot'} 
        id={this.makeId(date, timeIdx)}
        onClick={(e)=>{openShift(e.target.id)}}
      />
    )
  }

  render = () => {
    const { week, date } = this.props;

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1} />
            {week.map((day, idx) => (
              <Table.HeaderCell 
                className={day.isSame(date, 'day') ? 'active' : null} 
                textAlign='center' 
                key={idx}
                width={2}
              >
                <div>{day.format('ddd')}</div>
                <div className='table-day'>{day.format('D')}</div>
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {timeMap.map((time, idx) => {
            return (
              <Table.Row key={time}>
                <Table.Cell textAlign='right' className='time-slot header'>
                  {idx % 2 === 0 ? timeMapDisplay[idx] : null}
                </Table.Cell>
                {week.map((day) => (
                  this.renderCalendar(day, time, idx)
                ))}
              </Table.Row>
            )
            })}
        </Table.Body>
      </Table>
    )
  }
}

const mapStateToProps = (state) => ({
  week: state.week,
  day: state.date,
  schedule: state.schedule,
  bookings: state.bookings,
  services: state.services,
});

const mapDispatchToProps = (dispatch) => ({
  openShift: (id) => {
    console.log('id', id);
    const shiftId = id.split('-');
    const start = shiftId[2];
    const end = start + 1;
    const date = `2019-${shiftId[0]}-${shiftId[1]}`;
    dispatch(selectShiftDetail('date', date));
    dispatch(selectShiftDetail('start', start));
    dispatch(selectShiftDetail('end', end));
    dispatch(openModal());
    dispatch(selectShiftId(id));
    dispatch(setModalDate(date));
    dispatch(setModalTime(start));
  },
  openBookingModal: (id) => {
    const idArr = id.split('-');
    const date = `2019-${idArr[0]}-${idArr[1]}`;
    const start = idArr[2];
    dispatch(openBooking(date, idArr[2]));
    dispatch(setModalDate(date));
    dispatch(setModalTime(start));
  },
  openBookingInfo: (id) => {
    dispatch(openBookingInfo(id));
  },
  getSchedule: () => {
    dispatch(getSchedule());
  },
  getBookings: () => {
    dispatch(getBookings());
  }
});

export default connect (mapStateToProps, mapDispatchToProps)(Week);
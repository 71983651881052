import React, { Component } from 'react';
import { Search, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { selectMama } from '../../actions/mamas';

const algoliasearch = require('algoliasearch');

const client = algoliasearch('IWF0LVF4B0', '38ebaa71f8fa88dac767a63ee175ed93');
const index = client.initIndex('mama_id');

const resultRenderer = ({ name }) => <Label key={name} content={name} />
const initialState = { isLoading: false, results: [], value: '' }

class MamaSearch extends Component {
  state = initialState

  handleResultSelect = (e, { result }) => {
    console.log(result);
    this.setState({value: result.name});
    this.props.selectMama(result);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value })
    index.search(value, (err, { hits } = {}) => {
      console.log('hits', hits);
      console.log('state', this.state);
      this.setState({
        isLoading: false,
        results: hits,
      })
    });
  }

  render() {
    const { isLoading, value, results } = this.state

    return (
      <div id='mama-search'>
        <Search
          loading={isLoading}
          fluid
          onResultSelect={this.handleResultSelect}
          noResultsMessage='Add this mama'
          onSearchChange={this.handleSearchChange}
          results={results}
          resultRenderer={resultRenderer}
          value={value}
        />
      </div>
    )
  }
};

const mapStateToProps = (state) => ({
  mama: state.mama
});

const mapDispatchToProps = dispatch => ({
  selectMama: (mama) => {
    dispatch(selectMama(mama));
  }
});

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(MamaSearch);
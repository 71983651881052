const availability = {
  7: {
    5: {
      '8:00am': {
        status: 'available',
        start: true
      },
      '8:30am': {status: 'available'},
      '9:00am': {
        status: 'booked',
        booking: {
          id: '123',
          name: 'Cherry Xu',
          type: 'prenatal',
          duration: 60
        }
      },
      '9:30am': {status: 'booked'},
      '10:00am': {status: 'booked'},
      '10:30am': {
        status: 'booked',
        end: true,
      }
    },
    7: {
      '8:00am': {
        status: 'available',
        start: true,
      },
      '8:30am': {status: 'available'},
      '9:00am': {status: 'available'},
      '9:30am': {status: 'available'},
      '10:00am': {status: 'available'},
      '10:30am': {
        status: 'available',
        end: true,
      },
      '8:00pm': {
        status: 'available',
        start: true,
      },
      '8:30pm': {status: 'available'},
      '9:00pm': {status: 'available'},
      '9:30pm': {status: 'available'},
      '10:00pm': {status: 'available'},
      '10:30pm': {
        status: 'available',
        end: true,
      }
    }
  }
}

const initState = availability;

const schedule = (state = initState, action) => {
  switch (action.type) {
    case 'GET_SCHEDULE':
      return action.schedule;
    default:
      return state;
  }
}

export default schedule;
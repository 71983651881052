import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { store, history } from './store'
import { Switch } from 'react-router';
import MomentUtils from '@date-io/moment';
import Dashboard from './containers/Dashboard';
import Login from './containers/Login/Login';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Bookings from './containers/Bookings';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history} >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <div>
              <Switch>
                <Route exact path="/" component={Login} />>
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/bookings" component={Bookings} />
              </Switch>
            </div>
          </MuiPickersUtilsProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
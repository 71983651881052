import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { connect } from 'react-redux';
import Autocomplete from 'react-google-autocomplete';
import PhoneNumberMask from '../../components/PhoneNumberMask';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { closeModal } from '../../actions/modal';
import { saveMama } from '../../actions/mamas';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import { Button } from 'semantic-ui-react';
import WarningBox from '../../components/WarningBox/index';

const social = ['Instagram', 'Facebook', 'Google', 'Referred by a friend', 'ad', 'mommy connection', 'Kids & Co', 'Momsto', 'others'];

const styles = {
  icon: {
    height: '50px',
    width: '50px',
    padding: '12.5px',
    fontSize: '25px',
    color: 'rgba(0,0,0,.35)',
    cursor: 'pointer',
  },
  autoComplete: {
    border: 'none',
    borderBottom: '1px solid gray',
    paddingTop: '7px',
    paddingBottom: '7px',
    marginBottom: '20px'
  },
}

const NewMama = ({ open, closeModal, saveMama, mama }) => {
  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ tel, setTel ] = useState('');
  const [ address, setAddress ] = useState(null);
  const [ addressInput, setAddressInput ] = useState('');
  const [ unitNumber, setUnitNumber ] = useState(null);
  const [ referral, setReferral ] = useState('Instagram');

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={open}
    >
      <div className='modal-header'>
        <h2>New Mama</h2>
        <Icon onClick={closeModal} style={styles.icon}>close</Icon>
      </div>
      <div className='modal-body'>
        <InputLabel shrink htmlFor='first-name'>First Name</InputLabel>
        <TextField
          required
          name='firstName'
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <InputLabel shrink htmlFor='last-name'>Last Name</InputLabel>
        <TextField
          required
          name='lastName'
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          required
          name='tel'
          value={tel}
          onChange={(e) => setTel(e.target.value)}
          type="tel"
          label="Phone Number"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputComponent: PhoneNumberMask,
          }}
        />
        <InputLabel shrink htmlFor='email'>Email</InputLabel>
        <TextField
          required
          name='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
        />
        <InputLabel shrink htmlFor='address'>Address</InputLabel>
        <Autocomplete
          style={styles.autoComplete}
          value={addressInput}
          onChange={(e) => setAddressInput(e.target.value)}
          onPlaceSelected={(address) => {
            console.log(address);
            setAddress(address.formatted_address);
            setAddressInput(address.formatted_address);
          }}
          required
          name='address'
          types={['address']}
          placeholder=''
          componentRestrictions={{country: "ca"}}
        />
        <InputLabel shrink htmlFor='unit-number'>Unit #</InputLabel>
        <TextField
          name='unitNumber'
          value={unitNumber}
          onChange={(e) => setUnitNumber(e.target.value)}
        />
        <InputLabel shrink htmlFor='referral-source'>How did you hear about us?</InputLabel>
        <NativeSelect
          value={referral}
          input={<Input name="referralSource" />}
          onChange={(e) => setReferral(e.target.value)}
        >
          {social.map( src => (
            <option key={src} value={src}>{src}</option>
          ))}
        </NativeSelect>
        <Button 
          onClick={
            () => saveMama({
              firstName,
              lastName,
              email,
              tel,
              address,
              unitNumber,
              referral
              }
            )}>
          Save
        </Button>
        <WarningBox error={mama.errorMsg}/>
      </div>
    </Drawer>
  )
}

const mapStateToProps = (state) => ({
  open: state.modal.newMama,
  mama: state.saveMama
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => {
    dispatch(closeModal('newMama'));
  },
  saveMama: (mama) => {
    dispatch(saveMama(mama));
  }
});

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(NewMama);
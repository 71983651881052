const initState = {
  success: false,
  error: false,
  saving: false,
  errorMsg: '',
};

const saveMama = (state = initState, action) => {
  switch (action.type) {
    case 'SAVE_MAMA':
      return {
        success: false,
        saving: true,
        error: false,
        errorMsg: '',
      };
    case 'SAVE_MAMA_SUCCESS':
      return {
        success: true,
        saving: false,
        error: false,
        errorMsg: '',
      };
    case 'SAVE_MAMA_ERROR':
      return {
        success: false,
        saving: false,
        error: true,
        errorMsg: action.err,
      };
    default:
      return state;
  }
}

export default saveMama;
import React from 'react';
import Schedule from '../Schedule/index';


const Dashboard = () => (
  <div>
    <Schedule />
  </div>
)

export default Dashboard;
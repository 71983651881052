export const getSchedule = () => (
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const rmt = getState().shift.rmt;

    firestore.collection('schedules').doc(rmt).get().then((snapshot) => {
      dispatch({ 
        type: 'GET_SCHEDULE',
        schedule: snapshot.data() || {},
      });
    }
    ).catch((error) => console.log(error))
  } 
);


export const closeModal = (modal) => {
  return {
    type: 'CLOSE_MODAL',
    modal
  }
};

export const openModal = (id) => (
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const shiftSelect = getState().shift.shiftSelect;
    if (shiftSelect) {
      dispatch({
        type: 'OPEN_MODAL',
        modal: 'shift',
        id
      });
    } else {
      dispatch({
        type: 'OPEN_MODAL',
        modal: 'booking',
        id
      });
    }
  } 
);

export const openNewMama = () => ({
  type: 'OPEN_MODAL',
  modal: 'newMama'
});

export const openBookingInfo = (booking) => ({
  type: 'OPEN_BOOKINGINFO_MODAL',
  booking
});

export const closeBookingInfo = () => ({
  type: 'CLOSE_BOOKINGINFO_MODAL',
})

export const openBooking = (date, time) => ({
  type: 'OPEN_MODAL',
  modal: 'booking',
  date,
  time
});

export const setModalDate = (date) => (
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const dateArr = date.split('-');
    let newDate = date;
    if (Number(dateArr[1]) < 10) {
      newDate = `2020-${date.substring(5)}`
    }
    dispatch({
      type: 'SET_MODAL_DATE',
      date: newDate,
    })
  } 
);

export const setModalTime = (time) => ({
  type: 'SET_MODAL_TIME',
  time
});
import React from 'react';
import { Icon } from 'semantic-ui-react';
import './warningBox.css';

const warningMessages = {
  'auth/user-not-found': 'There isn’t an account associated with this email address. Please try another email.',
  'auth/invalid-email': 'This is an invalid email address - please try another email.',
  'auth/wrong-password': 'This is an incorrect password - please try another password.',
  'auth/weak-password': 'This password is too weak - please make sure you have at least 6 characters!',
  'auth/email-already-in-use': 'Looks like you already have an account with this Email!',
  'auth/popup-closed-by-user': 'Looks like you closed the popup - please try it again!',
  'location/no-location-selected': 'Please select a location provided by the Google dropdown!',
  'This mama already exists!': 'This mama already exists!',
}
const WarningBox = (props) => (
  <div className={props.error ? 'warning-box' : 'warning-box hide'}>
    <Icon name='warning sign' size='large' />
    <div>{warningMessages[props.error] || "hmmm... there seems to be an error - can't put my finger on what exactly it is..."}</div>
  </div>
);

export default WarningBox;
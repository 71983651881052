const algoliasearch = require('algoliasearch');
const fetch = require('node-fetch');
const createCustomerUrl = 'https://us-central1-mama-mobile-238622.cloudfunctions.net/api/stripe/createCustomer';

export const selectMama = (mama) => (
  (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const userRef = firestore.collection('users').doc(mama.email);

    userRef.get()
    .then(doc => {
      const mamaData = doc.data();
      dispatch({
        type: 'SELECT_MAMA',
        mama: mamaData,
      });
    })
    .catch(err => console.log('error', err));
  }
)

export const saveMama = (user) => (
  (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const client = algoliasearch('IWF0LVF4B0', '38ebaa71f8fa88dac767a63ee175ed93');
    const index = client.initIndex('mama_id');
    const email = user.email.toLowerCase();
    const firstName = user.firstName[0].toUpperCase() + user.firstName.substring(1).toLowerCase();
    const lastName = user.lastName[0].toUpperCase() + user.lastName.substring(1).toLowerCase();
    const name = `${firstName} ${lastName}`;

    user.name = name;
    user.email = email;
    user.firstName = firstName;
    user.lastName = lastName;
    const userRef = firestore.collection('users').doc(email);

    userRef.get()
    .then(snapshot => {
      if (!snapshot.exists) {
        fetch(createCustomerUrl, 
          {
            method: 'POST',
            body: JSON.stringify({ email })
          }
          ).then((res) => res.json())
          .then((data) => {
            user.stripe = data.id;
            const promise1 = userRef.set(user);
            const promise2 = index.addObject({...user, objectID: email});
  
            Promise.all([promise1, promise2]).then(() => {
              dispatch({
                type: 'SAVE_MAMA_SUCCESS'
              });
              dispatch({
                type: 'CLOSE_MODAL',
                modal: 'newMama',
              });
              selectMama(user);
            }).catch(err => {
              dispatch({
                type: 'SAVE_MAMA_ERROR',
                err
              });
            });
          }).catch((err) => {
            dispatch({
              type: 'SAVE_MAMA_ERROR',
              err
            });
          });
        } else {
          dispatch({
            type: 'SAVE_MAMA_ERROR',
            err: 'This mama already exists!'
          });
        }
    }).catch(err => console.log(err));
  }
);
const initState = {
  busy_mama_15: {
    id: 'busy_mama_15',
    name: 'Busy Mama Massage',
    price: 32,
    duration: 15,
  },
  busy_mama_30: {
    id: 'busy_mama_30',
    name: 'Busy Mama Massage',
    price: 64,
    duration: 30,
  },
  busy_mama_45: {
    id: 'busy_mama_45',
    name: 'Busy Mama Massage',
    price: 96,
    duration: 45,
  },
  prenatal_60: {
    id: 'prenatal_60',
    name: 'Prenatal Massage',
    price: 128,
    duration: 60,
  },
  prenatal_90: {
    id: 'prenatal_90',
    name: 'Prenatal Massage',
    price: 188,
    duration: 90,
  },
  postnatal_60: {
    id: 'postnatal_60',
    name: 'Postnatal Massage',
    price: 128,
    duration: 60,
  },
  postnatal_90: {
    id: 'postnatal_90',
    name: 'Postnatal Massage',
    price: 188,
    duration: 90,
  },
  busy_mama_60: {
    id: 'busy_mama_60',
    name: 'Busy Mama Massage',
    price: 128,
    duration: 60,
  },
  busy_mama_90: {
    id: 'busy_mama_90',
    name: 'Busy Mama Massage',
    price: 188,
    duration: 90,
  },
  mama_papa_120: {
    id: 'mama_papa_120',
    name: 'Mama Papa Massage',
    price: 256,
    duration: 120,
  },
  mama_papa_180: {
    id: 'mama_papa_180',
    name: 'Mama Papa Massage',
    price: 376,
    duration: 180,
  },
  baby_massage_lesson: {
    id: 'baby_massage_lesson',
    name: 'Baby Massage Lesson',
    price: 128,
    duration: 60,
  },
  baby_30: {
    id: 'baby_30',
    name: 'Baby Massage',
    price: 64,
    duration: 30,
  },
  papa_60: {
    id: 'papa_60',
    name: 'Papa Massage',
    price: 128,
    duration: 60,
  },
  papa_90: {
    id: 'papa_90',
    name: 'Papa Massage',
    price: 188,
    duration: 90,
  },
  facial_10: {
    id: 'facial_massage_10',
    name: 'Facial Massage',
    price: 22,
    duration: 0,
  },
  guasha: {
    id: 'guasha',
    name: 'Gua-Sha Facial Massage',
    price: 25,
    duration: 0,
  },
  group_120: {
    id: 'group_120',
    name: 'Massge Party',
    price: 286,
    duration: 120,
  },
  group_180: {
    id: 'group_180',
    name: 'Massage Party',
    price: 414,
    duration: 180,
  },
};

const services = (state = initState, action) => {
  switch (action.type) {
    case 'GET_SERVICES':
      return action.services;
    default:
      return state;
  }
}

export default services;
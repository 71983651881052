import 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Button } from 'semantic-ui-react';
import { selectDateFrom, selectDateTo, getBookingsSchedule } from '../../actions/bookingsSchedule';

const DateSelectors = ({ selectDateFrom, selectDateTo, bookingsSchedule, getBookingsSchedule}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Grid container justify="center" alignItems="center">
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-from"
        label="Date From"
        value={bookingsSchedule.dateFrom}
        onChange={selectDateFrom}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        style={{marginRight: '30px'}}
      />
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-to"
        label="Date To"
        value={bookingsSchedule.dateTo}
        onChange={selectDateTo}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
      <Button onClick={getBookingsSchedule} id="get-booking-button">Get Bookings</Button>
    </Grid>
  </MuiPickersUtilsProvider>
);

const mapStateToProps = (state) => ({
  bookingsSchedule: state.bookingsSchedule
});

const mapDispatchToProps = dispatch => ({
  selectDateFrom: (date) => {
    dispatch(selectDateFrom(date));
  },
  selectDateTo: (date) => {
    dispatch(selectDateTo(date));
  },
  getBookingsSchedule: () => {
    dispatch(getBookingsSchedule());
  }
});

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(DateSelectors);
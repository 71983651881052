const timeMap = [
  '7:00am', '7:30am', '8:00am', '8:30am',
  '9:00am', '9:30am', '10:00am', '10:30am',
  '11:00am', '11:30am', '12:00pm', '12:30pm',
  '1:00pm', '1:30pm', '2:00pm', '2:30pm',
  '3:00pm', '3:30pm', '4:00pm', '4:30pm',
  '5:00pm', '5:30pm', '6:00pm', '6:30pm',
  '7:00pm', '7:30pm', '8:00pm', '8:30pm',
  '9:00pm', '9:30pm', '10:00pm', '10:30pm',
];

const cancelBookingSchedule = (day, bookingId, rmt, scheduleRef) => {
  scheduleRef.get().then((doc) => {
    const scheduleMonth = doc.data();
    const newDay = scheduleMonth[day];
    
    delete newDay[rmt][bookingId];
    scheduleRef.update({
      [day]: newDay,
    }).then(() => console.log('update success'))
    .catch(err => console.log('error from updating schedule', err))
  }).catch((err) => console.log('error from saving booking schedule', err))
}

const cancelBooking = (booking, bookingId) => (
  (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const { rmt_id, appt } = booking;
    const date = new Date(appt.date);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const timeIdx = timeMap.indexOf(appt.time);
    const durationSlots = appt.duration/30 + 2;
    const statusUpdate = 'appt.status';
    const dayUpdate = `${month}.${day}`;

    const bookingRef = firestore.collection('bookings').doc(bookingId);
    const scheduleRef = firestore.collection('schedules').doc(rmt_id);
    const bookingScheduleRef = firestore.collection(`bookings_schedule_${year}`).doc(month.toString());

    cancelBookingSchedule(day, bookingId, rmt_id, bookingScheduleRef);

    bookingRef.update({
      [statusUpdate]: 'cancelled',
    })
      .then(() => console.log('appt cancelled'))
      .catch((err) => console.log('error from cancelling booking', err));

    scheduleRef.get()
    .then(doc => {
      const schedule = doc.data();
      const scheduleDay = schedule[month][day];

      for (let i = 0; i < durationSlots; i++) {
        const currentTimeIdx = timeIdx + i;
        const currentTime = timeMap[currentTimeIdx];
        const scheduleTime = scheduleDay[currentTime];

        if (scheduleTime.booking.id === bookingId) {
          delete scheduleDay[currentTime];
        }
      };

      scheduleRef.update({
        [dayUpdate]: scheduleDay
      }).then(() => console.log('updated'))
      .catch((err) => console.log('error from updating', err));
    })
    .catch((err) => console.log('error from getting schedule', err));
  }
);

export default cancelBooking;
export const selectDateFrom = (date) => ({
  type: 'SELECT_DATE_FROM',
  date,
});

export const selectDateTo = (date) => ({
  type: 'SELECT_DATE_TO',
  date,
});

const getDateObject = date => ({
  date,
  month: date.getMonth(),
  year: date.getFullYear(),
  day: date.getDate(),
  string: date.toString(),
})

export const getBookingsSchedule = () => (
  (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const state = getState();
    const { dateFrom, dateTo } = state.bookingsSchedule;
    const from = getDateObject(dateFrom);
    const to = getDateObject(dateTo);
    let bookings = {};
    const Total = 0;
    const count = 0;
  
    firestore.collection(`bookings_schedule_${from.year}`).get().then((querySnapshot) => {
      const bookingsSchedule = {};
      querySnapshot.forEach(doc => bookingsSchedule[doc.id] = doc.data());
      if (from.string === to.string) {
        bookings = bookingsSchedule[from.month][from.day];
        const rmts = Object.keys(bookings);

        // for (let i = 0; i < rmts.length; i++) {
        //   const rmtSchedule = bookings[rmts[i]];
        //   for (let j = 0; j < rmtSchedule.length)
        // }
      }
      if (from.year === to.year) {
        dispatch({ 
          type: 'GET_BOOKINGS_SCHEDULE',
          bookings: bookingsSchedule,
        });
      }
    }).catch((error) => console.log(error))
  } 
);

import getBookings from './getBookings';

const saveBookingSchedule = (day, bookingId, booking, rmt, scheduleRef) => {
  scheduleRef.get().then((doc) => {
    const scheduleMonth = doc.data();
    let newDay;

    if (!scheduleMonth || !scheduleMonth[day]) {
      newDay = {
        [rmt]: {
          [bookingId]: booking,
        }
      }
    } else if (!scheduleMonth[day][rmt]) {
      newDay = { ...scheduleMonth[day] };
      newDay[rmt] = {
        [bookingId]: booking,
      }
    } else {
      newDay = { ...scheduleMonth[day] };
      newDay[rmt][bookingId] = booking;
    }
    console.log('newDay', newDay);

    if (!scheduleMonth) {
      scheduleRef.set({
        [day]: newDay
      }).then(() => console.log('update success'))
      .catch(err => console.log('error from updating schedule', err));
    } else {
      scheduleRef.update({
        [day]: newDay,
      }).then(() => console.log('update success'))
      .catch(err => console.log('error from updating schedule', err));
    }
  }).catch((err) => console.log('error from saving booking schedule', err))
}

export const formatBookings = () => (
  (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('bookings').get().then((querySnapshot) => {
      const bookings = {};
      querySnapshot.forEach(doc => {
        bookings[doc.id] = doc.data();
      });
    }).catch((error) => console.log(error))

    // const bookingRef = firestore.collection('bookings').doc(bookingId);
    // const scheduleRef = firestore.collection('schedules').doc(rmt_id);
    // const bookingScheduleRef = firestore.collection(`bookings_schedule_${year}`).doc(month.toString());

    // bookingRef.set(booking)
    // .then(() => {
    //   scheduleRef.get()
    //     .then((doc) => {
    //       const updateDay = `${month}.${day}`;
    //       if (doc.exists) {
    //         const schedule = doc.data();
    //         const scheduleMonth = schedule[month] ? schedule[month] : {};
    //         const scheduleDay = scheduleMonth[day] ? scheduleMonth[day] : {};
    //         const newDay = getDaySchedule(scheduleDay, timeIdxNumber, duration, bookingId);

    //         scheduleRef.update({
    //           [updateDay]: newDay,
    //         }).then(() => console.log('done', newDay))
    //           .catch(err => console.log('error from updating schedule', err));
    //       } else {
    //         const newDay = getDaySchedule({}, timeIdxNumber, duration, bookingId);
    //         const scheduleMonth = {
    //           [day]: newDay,
    //         }
    //         scheduleRef.set({
    //           [month]: scheduleMonth,
    //         }).then(() => console.log('done', newDay))
    //           .catch(err => console.log('error from updating schedule', err));
    //       }
          
    //       dispatch(closeModal('booking'));
    //     }).catch(err => console.log('error from getting schedule', err));
    // })
    // .catch(err => console.log('error from setting booking', err));
  }
);
import React from 'react';
import { connect } from 'react-redux';
import { formatBookings } from '../../actions/formatBookings';
import BookingTable from './BookingTable';
import DateSelector from './DateSelector';
import './bookings.css';

const Bookings = ({ formatBookings }) => (
  <div>
    {/* <button onClick={formatBookings}>Format Bookings</button> */}
    <DateSelector />
    <BookingTable />
  </div>
);

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => ({
  formatBookings: () => {
    dispatch(formatBookings());
  },
});

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(Bookings);
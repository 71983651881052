const initState = {
  shiftSelect: false,
  shiftId: '7-9-8:00am',
  date: '',
  start: '',
  end: '',
  type: '',
  rmt: 'pmacneil@bell.net',
  duration: null,
};

const shift = (state = initState, action) => {
  switch (action.type) {
    case 'SELECT_SHIFT':
      return {
        ...state,
        shiftSelect: !state.shiftSelect,
      };
    case 'SELECT_SHIFT_ID':
      return {
        ...state,
        shiftId: action.shiftId,
      };
    case 'SELECT_SHIFT_DETAIL':
      return {
        ...state,
        [action.key]: action.value,
      };
    default:
      return state;
  }
}

export default shift;
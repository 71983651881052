const initState = [];

const rmts = (state = initState, action) => {
  switch (action.type) {
    case 'GET_RMTS':
      return action.rmts;
    default:
      return state;
  }
}

export default rmts;
import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { connect } from 'react-redux';
import { Card } from 'semantic-ui-react';
import { closeModal, openNewMama, setModalDate, setModalTime } from '../../actions/modal';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { Button } from 'semantic-ui-react';
import MenuItem from '@material-ui/core/MenuItem';
import Search from './Search';
import { saveBooking } from '../../actions/saveBooking';
import getBookings from '../../actions/getBookings';
import { cancelShift } from '../../actions/shift';

const timeMap = [
  '7:00am', '7:30am', '8:00am', '8:30am',
  '9:00am', '9:30am', '10:00am', '10:30am',
  '11:00am', '11:30am', '12:00pm', '12:30pm', 
  '1:00pm', '1:30pm', '2:00pm', '2:30pm', 
  '3:00pm', '3:30pm', '4:00pm', '4:30pm', 
  '5:00pm', '5:30pm', '6:00pm', '6:30pm', 
  '7:00pm', '7:30pm', '8:00pm', '8:30pm', 
  '9:00pm', '9:30pm', '10:00pm', '10:30pm',
  '11:00pm'
];

const SERVICES = [
  'prenatal_60', 'prenatal_90', 
  'postnatal_60', 'postnatal_90',
  'busy_mama_15', 'busy_mama_30', 'busy_mama_45', 'busy_mama_60', 'busy_mama_90',
  'mama_papa_120', 'mama_papa_180',
  'baby_30', 'baby_massage_lesson',
  'papa_60', 'papa_90', 'facial_10', 'guasha', 'group_120', 'group_180',
];

const styles = {
  icon: {
    height: '50px',
    width: '50px',
    padding: '12.5px',
    fontSize: '25px',
    color: 'rgba(0,0,0,.35)',
    cursor: 'pointer',
  }
}

const Booking = ({ open, closeModal, openNewMama, date, timeIdx, setDate, setTime, mama, shift, saveBooking, rmts, cancelShift }) => {
  const [ services, setServices ] = useState([]);
  let rmtName;
  let rmtNumber;
  rmts.forEach(r => {
    if (r.id === shift.rmt) {
      rmtName = r.name;
      rmtNumber = r.rmtNumber;
    }
  });

  const booking = {
    services, 
    mama_id: mama.email, 
    rmt_id: shift.rmt, 
    location: {
      address: mama.address || '',
      unit: mama.unitNumber || '',
    },
    date,
    timeIdx,
    rmt_name: rmtName,
    rmt_number: rmtNumber,
  }

  const handleChange = event => {
    setServices(event.target.value);
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={open}
    >
      <div className='modal-header'>
        <h2>NEW BOOKING</h2>
        <Icon onClick={closeModal} style={styles.icon}>close</Icon>
      </div>
      <div className='modal-body'>
        <div id='mama-header'>
          <InputLabel shrink htmlFor='select-mama'>Select a Mama</InputLabel>
          <Button id='new-mama-button' onClick={openNewMama}>New Mama</Button>
        </div>
        <Search />
        <Card className={!mama.name ? 'hide mama-card' : 'mama-card'}>
          <Card.Content>
            <Card.Header id='card-name'>{mama.name}</Card.Header>
            <Card.Meta>{mama.tel}</Card.Meta>
            <Card.Meta>{mama.email}</Card.Meta>
            <Card.Description id='card-address'>{mama.address}</Card.Description>
          </Card.Content>
        </Card>
        <InputLabel htmlFor="select-multiple-chip">Services</InputLabel>
        <Select
          multiple
          value={services}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => (
            <div>
              {selected.map(value => (
                <Chip key={value} label={value} />
              ))}
            </div>
          )}
        >
          {SERVICES.map(service => (
            <MenuItem key={service} value={service}>
              {service}
            </MenuItem>
          ))}
        </Select>
        <TextField
          id="booking-date"
          label="Date"
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <InputLabel shrink htmlFor='booking-start'>Start At</InputLabel>
        <NativeSelect
          input={<Input name="Start At" id="shift-start" />}
          value={timeIdx}
          onChange={(e) => setTime(e.target.value)}
        >
          {timeMap.map(( time, idx ) => (
            <option key={idx} value={idx}>{time}</option>
          ))}
        </NativeSelect>
        <Button onClick={() => saveBooking(booking)}>Save</Button>
        <br/>
        <a onClick={() => cancelShift()}>Cancel Shift</a>
      </div>
    </Drawer>
  )
}

const mapStateToProps = (state) => ({
  open: state.modal.booking,
  shift: state.shift,
  bookingModalId: state.modal.bookingId,
  date: state.modal.date,
  timeIdx: state.modal.time,
  mama: state.mama,
  rmts: state.rmts,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => {
    dispatch(closeModal('booking'));
  },
  openNewMama: () => {
    dispatch(openNewMama());
  },
  setDate: (date) => {
    dispatch(setModalDate(date));
  },
  setTime: (timeIdx) => {
    dispatch(setModalTime(timeIdx));
  },
  saveBooking: (booking) => {
    dispatch(saveBooking(booking));
    dispatch(getBookings());
  },
  cancelShift: () => {
    dispatch(cancelShift());
  }
});

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(Booking);
const initState = {
  bookings: {},
  dateFrom: new Date(),
  dateTo: new Date(),
};

const bookingsSchedule = (state = initState, action) => {
  switch (action.type) {
    case 'SELECT_DATE_FROM':
      return {
        ...state,
        dateFrom: action.date
      };
    case 'SELECT_DATE_TO':
      return {
        ...state,
        dateTo: action.date
      };
    case 'GET_BOOKINGS_SCHEDULE':
      return {
        ...state,
        bookings: action.bookings,
      };
    default:
      return state;
  }
}

export default bookingsSchedule;